import React, { useContext, useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import Run from '../../images/hunter.webp';
import indianParentsMeme from "../../images/memes/indianParents.webp"
import pikaMeme from "../../images/memes/badDay.webp"
import jokerMeme from "../../images/memes/heathLedger.webp"
import thinkingMeme from "../../images/memes/thinkingGal.webp"
import lokiMeme from '../../images/loki.webp';
import chiefMeme from '../../images/chief.webp';
import haderMeme from '../../images/hader.webp';
import jnickMeme from '../../images/jnick.gif';
import priceMeme from '../../images/price.webp';
import { TransitionPortal } from 'gatsby-plugin-transition-link';
import ReactPlayer from 'react-player';

const memeData = [
  {
    id: 1,
    name: 'captainprice',
    meme: priceMeme,
    quote:
      'Right. What the hell kind of name is “Soap”, eh? How’d a muppet like you pass selection?',
    author: 'Captain Price',
  },
  {
    id: 2,
    name: 'jacknicholson',
    meme: jnickMeme,
    quote: 'Belgium, Belgium...',
    author: 'Saw it in a meme compilation',
  },

  {
    id: 3,
    name: 'loki',
    meme: lokiMeme,
    quote: "Now if you'll excuse me, I have to destroy Jotunheim.",
    author: 'Loki (God of Mischeif)',
  },
  {
    id: 4,
    name: 'mastercheif',
    meme: chiefMeme,
    quote: "You've completed your mission, Spartan Locke. Mine is just beginning..",
    author: ' Master Chief (Halo 5)',
  },
  {
    id: 5,
    name: 'snipersniper',
    meme: haderMeme,
    quote: 'Devs when testers find the bugs in the final build',
    author: 'Californians (SNL)',
  },
  {
    id: 6,
    name: 'indianparents',
    meme: indianParentsMeme,
    quote: 'Indian parents when you do not want to become an Engineer or Doctor',
    author: 'True Stories'
  },
  {
    id: 7,
    name: 'pickachipokemon ',
    meme: pikaMeme,
    quote: 'If you were having a bad day, now you are not',
    author: 'You are welcome'
  },
  {
    id: 8,
    name: 'jokerMeme',
    meme: jokerMeme,
    quote: 'This is what happens when an unstoppable force meets an immovable object',
    author: 'Joker (The Dark Knight)'
  },
  {
    id: 9,
    name: 'thinkingthinker',
    meme: thinkingMeme,
    quote: 'Every developers face before googling the concept',
    author: 'Don`t deny no'
  }
];

const NotFoundComponent = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [currentMeme, setCurrentMeme] = useState({});
  const layoutContents = useRef();
  const transitionCover = useRef();
  var random_idx = Math.floor(Math.random() * memeData.length);
  useEffect(() => {
   
    setCurrentMeme(memeData[random_idx]);

    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);


  return (
    <section id="hero" className="jumbotron" ref={layoutContents}>
      <Container className="px-0 d-flex flex-column">
        <Pulse>
          <div className="px-0 d-flex justify-content-center mb-5 " style={{ minHeight: '300px' }}>
            <ReactPlayer
              className="meme-player"
              url={Run}
              light={currentMeme.meme}
              width={isMobile ? '100%' : '100%'}
              playIcon={() => null}
            />
          </div>
        </Pulse>
        <Fade top duration={800} delay={400} distance="20px">
          <h1 className="hero-title glitch mx-auto mb-0">404!</h1>
        </Fade>
        <Fade bottom duration={800} delay={800} distance="30px">
          <p className="hero-sub-title text-center notfound-text">{currentMeme.quote}</p>
        </Fade>
        <Fade bottom duration={1000} delay={1200} distance="30px">
          <p className="hero-sub-title text-center notfound-subtext">- {currentMeme.author}</p>
        </Fade>
      </Container>
      <TransitionPortal>
        <div
          ref={transitionCover}
          style={{
            position: 'relative',
            background: '#131313',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'none',
          }}
        />
      </TransitionPortal>
    </section>
  );
};

export default NotFoundComponent;
