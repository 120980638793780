import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeroLayout from '../components/Layouts/HeroLayout';
import '../style/main.scss';
import App from '../components/App';
import NotFoundComponent from '../components/NotFoundComponent';

export default () => {
  return (
    <HeroLayout metaTitle="404 | Not Found" metaDescription='The page you are looking for, does not exists, maybe Thanos had a word with it.'>
      <NotFoundComponent />
    </HeroLayout>
  );
};
